<template>
  <footer id="Footer" class="footer">
    <at-footer-services></at-footer-services>
    <at-footer-news-latter></at-footer-news-latter>
    <at-footer-contact></at-footer-contact>
    <at-footer-catagory></at-footer-catagory>
    <at-footer-payment></at-footer-payment>
    <at-footer-copy-right></at-footer-copy-right>
  </footer>
</template>

<script>
import AtFooterServices from "./FooterServices.vue";
import AtFooterNewsLatter from "./FooterNewsLatter.vue";
import AtFooterContact from "./FooterContact.vue";
import AtFooterCatagory from "./FooterCatagory.vue";
import AtFooterPayment from "./FooterPayment.vue";
import AtFooterCopyRight from "./FooterCopyRight.vue";

export default {
  components: {
    AtFooterServices,
    AtFooterNewsLatter,
    AtFooterContact,
    AtFooterCatagory,
    AtFooterPayment,
    AtFooterCopyRight,
  },
};
</script>

<style>
</style>