<template>
  <div class="footer-contact-info">
    <div class="container">
      <div class="row contact-info-row justify-content-start">
        <div class="col col-md-2 contact-clm">
          <div class="footer-clm">
            <div class="contacts-block" v-if="typeof contactData == 'object'">
              <h3 class="text-uppercase block-heading clm-title semiBold-15">
                {{ contactData.title }}
              </h3>
              <cmsBlockSimple
                v-if="typeof contactData == 'object'"
                :identifier="contactData.identifier"
              />
            </div>
          </div>
        </div>
        <div class="col col-md-4 general-info-clm">
          <div class="footer-clm">
            <h3 class="clm-title semiBold-15">{{ generalInfoFooter.title }}</h3>
            <div class="multiple-link-wrap">
              <v-runtime-template
                :class="['cms-block-content more']"
                :template="`<div>` + generalInfoFooter.content + `</div>`"
              ></v-runtime-template>
            </div>
          </div>
        </div>
        <div class="col col-md-2 brands-clm">
          <div class="footer-clm">
            <h3 class="clm-title semiBold-15">{{ brands.title }}</h3>
            <div class="multiple-link-wrap">
              <v-runtime-template
                :class="['cms-block-content more']"
                :template="`<div>` + brands.content + `</div>`"
              ></v-runtime-template>
            </div>
          </div>
        </div>
        <div class="col col-md-a logo-clm">
          <div class="footer-clm">
            <div class="footer-brand">
              <img :src="imgUrl('footer-brand.png')" />
            </div>
            <a href="javascript:void(0)"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  components: { VRuntimeTemplate, cmsBlockSimple },

  computed: {
    brands() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_menu_quick_links"
      );
    },
    generalInfoFooter() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "general_info_footer"
      );
    },
    footerData() {
     
      return [
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_information"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_service"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_quick_links"
        ),
      ];
    },
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_contact"
      );
    },
  },
  methods: {
    imgUrl(filename) {
      return getImageUrl(filename);
    },
  },
};
</script>

<style>
</style>