<template>
  <div class="footer-category">
    <div class="container">
      <div class="row">
        <div class="col col-md-12">
          <div class="footer-clm">
            <h4 class="clm-title semiBold-15">{{ footerCatagories.title }}</h4>
            <div class="multiple-link-wrap">
              <v-runtime-template
                :class="['cms-block-content']"
                :template="footerCatagories.content"
              ></v-runtime-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VRuntimeTemplate from "v-runtime-template";

export default {
  components: { VRuntimeTemplate },

  computed: {
    footerCatagories() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "categories_footer"
      );
    },
  },
};
</script>

<style>
</style>