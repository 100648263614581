<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    accordion="header-accordion"
    :class="{ collapsed: visible, login: isLoggedIn, 'not-login': !isLoggedIn }"
    :aria-expanded="visible"
    class="top-icons--drop mini-cart wishlist-cart"
    :visible="visible"
  >
    <!-- v-model="visible" -->
    <span v-if="icon.dropHeading" class="drop-heading">{{
      icon.dropHeading
    }}</span>
    <ul class="cart-drop-item">
      <li v-for="(dropItem, index) of icon.dropItems" :key="`wl-${index}`">
        <div class="top" @click.stop="removeItem(dropItem.id)">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </div>

        <div class="single-item-wrap">
          <div class="first">
            <div class="left">
              <b-link
                :to="`/${dropItem.product.url_key}`"
                class="d-flex align-items-start"
              >
                <div class="drop-item--img">
                  <img
                    :src="dropItem.product.image.url"
                    :alt="dropItem.product.sku"
                    width="75"
                    height="75"
                  />
                </div>
                <div class="product-info">
                  <span class="product-name d-block">{{
                    dropItem.product.name
                  }}</span>
                  <span class="price d-block">{{
                    formatCurrency(
                      dropItem.product.price_range.minimum_price.final_price
                        .value
                    )
                  }}</span>
                </div>
              </b-link>
            </div>
            <div class="right">
              <div class="drop-item--info"></div>
            </div>
          </div>
        </div>
        <!-- <a
          href="#"
          class="lnr lnr-cross"
          @click.prevent="removeItem(dropItem.id)"
        ></a> -->
      </li>
    </ul>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
    visible: { type: Boolean },
  },
  mounted() {
    // document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    // document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      // visible: false,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        // this.visible = false;
        this.$emit("close")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
