<template>
  <div class="footer-services">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col col-md-auto">
          <cmsBlockSimple
            v-if="typeof FooterUsp1 == 'object'"
            :identifier="FooterUsp1.identifier"
          />
        </div>
        <div class="col col-md-auto">
          <cmsBlockSimple
            v-if="typeof FooterUsp2 == 'object'"
            :identifier="FooterUsp2.identifier"
          />
        </div>
        <div class="col col-md-auto">
          <cmsBlockSimple
            v-if="typeof FooterUsp3 == 'object'"
            :identifier="FooterUsp3.identifier"
          />
        </div>
        <div class="col col-md-auto">
          <cmsBlockSimple
            v-if="typeof FooterUsp4 == 'object'"
            :identifier="FooterUsp4.identifier"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
export default {
  components: {
    cmsBlockSimple,
  },
  computed: {
    FooterUsp1() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_usp_1"
      );
    },
    FooterUsp2() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_usp_2"
      );
    },
    FooterUsp3() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_usp_3"
      );
    },
    FooterUsp4() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_usp_4"
      );
    },
  },
};
</script>

<style>
</style>