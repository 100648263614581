<template>
  <div class="top-header">
    <div class="container">
      <div class="mobile-search-bar" :class="{ show: showSearchMobile }">
        <HeaderSearch @close-search-m="handleSearchMobile(false)" />
      </div>
      <div class="row align-items-center mobile-menu">
        <div class="col-md-4 col-2">
          <div class="menu-trigger-wrap">
            <button
              @click="$emit('ham-toggle')"
              class="menu-trigger btn text-white regular-22"
            >
              <!-- <img src="../../assets/imgs/menu-trigger-bar.svg" alt=""> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="19"
                viewBox="0 0 27 19"
              >
                <g>
                  <g>
                    <path d="M1.5 9.5h24m-24 8h24m-24-16h24" />
                    <path
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-miterlimit="20"
                      stroke-width="2"
                      d="M1.5 9.5h24m-24 8h24m-24-16h24"
                    />
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="col-md-8 col-10">
          <HeaderTopIcons
            showType="O"
            @show-search-m="handleSearchMobile(true)"
          />
          <!-- <ul class="icon-link-wrap">
            <li>
              <router-link :to="{ name: 'contact' }">
                <a href="#" class="regular-12">
                  <span class="icon"><i class="fas fa-phone-alt"></i></span
                  ><span>+31(0)12-3456789</span>
                </a>
              </router-link>
            </li>
            <li>
              <a href="#" class="regular-12"
                ><span class="icon"><i class="fas fa-user-alt"></i></span
                ><span>Inloggen</span></a
              >
            </li>
            <li>
              <a href="#" class="regular-12"
                ><span class="icon"><i class="fas fa-shopping-basket"></i></span
                ><span>Winkelmandje</span></a
              >
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTopIcons from "@/base/core/components/header/HeaderTopIcons.vue";
import HeaderSearch from "@/base/core/components/header/HeaderSearch.vue";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  components: {
    HeaderTopIcons,
    HeaderSearch,
  },
  data: () => ({
    showSearchMobile: false,
  }),
  methods: {
    handleSearchMobile(value) {
      this.showSearchMobile = value;
    },
    imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
};
</script>

<style>
</style>