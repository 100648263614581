<template>
  <div class="footer-news-latter">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="news-letter-filed-wrap">
            <label for="" class="semiBold-15">{{
              $t("newsletter_subscriptions")
            }}</label>
            <div class="news-letter-input-filed c-input-item">
              <input
                type="email"
                name=""
                id=""
                class="c-input-filed"
                :placeholder="$t('email_placeholder')"
                v-model="email"
              />
              <button
                @click="subscribe(email)"
                class="c-btn c-btn-primary regular-12"
              >
                {{ $t("register") }}
              </button>
              <!-- <small class="form-error w-100">Dit veld is verplicht</small> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="footer-social">
            <label class="semiBold-15 mb-0">{{ $t("follow_us") }}: </label>
            <cmsBlockSimple
              v-if="typeof socialMedia == 'object'"
              :identifier="socialMedia.identifier"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@storefront/core/modules/user/mixins";
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";

export default {
   components: {
    cmsBlockSimple,
  },
  mixins: [User],
  data() {
    return { email: "" };
  },
  computed: {
    socialMedia() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "social_plateform"
      );
    },
  },
};
</script>

<style></style>
