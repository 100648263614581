<template>
  <div class="footer-copy-right">
    <div class="container">
      <div class="row">
        <div class="col col-md-12">
          <div class="footer-copy-right-wrap">
            <p class="copy-text mb-0 regular-12">
              {{ $t("prices_include_vat") }} -
              {{ $t("terms_and_conditions") }} -
              <a href="#">{{ $t("footer_privacy") }}</a> -
              <a href="#">{{ $t("footer_cookies") }}</a>
              {{ $t("footer_copyright") }} {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImageUrl from "@/base/helpers/getImageUrl";

export default {
  methods: {
    imgUrl(filename) {
      return getImageUrl(filename);
    },
  },
  computed: {},
};
</script>

<style></style>
