<template>
  <nav id="nav" class="menu">
    <div class="container">
      <div class="main-nav-wrap">
        <b-link href="#" class="nav-opener" @click="toggleNav"
          ><span></span
        ></b-link>
        <div
          class="nav-overlay"
          @click="toggleNav"
          :class="{ show: navExpanded }"
        ></div>
        <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
        <div class="nav-wrap">
          <div class="nav-left">
            <transition name="slide-fade">
              <div class="nav-collapse" :class="{ show: navExpanded }">
                <NavbarContent @clicked="toggleNav" />
              </div>
            </transition>
            <HeaderSearch />
          </div>
          <div class="nav-right">
            <b-link
              to="/customer-service"
              href="javascript:void(0)"
              class="c-btn c-btn-dark"
              >{{ $t("customer_service") }}</b-link
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarContent from "@/base/core/components/header/NavbarContent.vue";
import { Logger } from "@storefront/core/lib/logger";
import HeaderSearch from "@/base/core/components/header/HeaderSearch.vue";

export default {
  name: "Navbar",
  components: {
    NavbarContent,
    HeaderSearch,
  },
  data: () => ({}),
  computed: {
    navExpanded: {
      get() {
        return this.$store.getters["menu/getNavbar"];
      },
      set(val) {
        Logger.debug("navExpanded set called", "Navbar", val)();
      },
    },
  },

  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    onClick() {},
    toggleNav() {
      this.$store.dispatch("menu/toggleNavbar");
    },
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 991) this.navExpanded = false;
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
