<template>
  <div class="footer-payment-card">
    <div class="container">
      <div class="row">
        <div class="col col-md-12" v-if="typeof paymentPartners == 'object'">
          <ul>
            <li v-for="(image, index) in paymentPartners.slides" :key="index">
              <b-link class="img" :href="image.link" target="_blank">
                <img
                  :src="image.media.url"
                  :alt="image.title"
                  class="delivery-icons"
                />
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
  },
};
</script>

<style>
</style>